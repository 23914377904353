<template>
  <div class='corp-word-create'>
    <router-header :routerHeaderInfo="routerHeaderInfo"></router-header>
    <a-form class="create-word-form"
            ref="formRef"
            :model="wordInfo"
            :rules="rules">
      <a-form-item label="分组"
                   ref="group"
                   name="group">
        <a-select v-model:value="wordInfo.groupId"
                  placeholder="选择分组">
          <a-select-option v-for="group in groupList"
                           :key="group.id">
            {{group.name}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="标题"
                   name="title">
        <a-input class="word-title"
                 v-model:value="wordInfo.title"
                 placeholder="请输入标题名称" />
      </a-form-item>
      <div class="word__content"
           v-for="(content, index) in wordInfo.contents"
           :key="content.id">
        <div class="radio-list">
          <a-radio-group v-model:value="content.type"
                         @change="content.error = false">
            <a-radio value="text">文字</a-radio>
            <a-radio value="img">图片</a-radio>
            <a-radio value="link">链接</a-radio>
            <a-radio value="file">PDF</a-radio>
          </a-radio-group>
          <DeleteOutlined v-if="wordInfo.contents.length >= 2"
                          @click="deleteContent(index)" />
        </div>
        <div class="content__container"
             :class="{error: content.error}">
          <a-textarea class="word-text"
                      v-show="content.type === 'text'"
                      placeholder="请输入话术"
                      v-model:value="content.content"
                      @change="changeText(index)"
                      :maxlength="600"
                      showCount></a-textarea>
          <upload-image class="word-img"
                        v-show="content.type === 'img'"
                        :imageUrl="content.bucketUri"
                        :apiName="'corpWord'"
                        @child-image="handleImgUrl($event, index)">
          </upload-image>
          <div v-show="content.type === 'link'"
               class="word-link">
            <a-form-item name="link"
                         :wrapper-col="{span: 22}">
              <a-input v-model:value="content.link"
                       @keyup="getLinkInfo(content)"
                       placeholder="链接地址请以http:// 或 https://开头">
              </a-input>
            </a-form-item>
            <a-form-item label="链接标题"
                         name="title"
                         :wrapper-col="{span: 13}">
              <a-input v-model:value="content.title"
                       @change="validateLink(content)"></a-input>
            </a-form-item>
            <a-form-item label="链接摘要"
                         name="abstract"
                         :wrapper-col="{span: 13}">
              <a-input v-model:value="content.summary"
                       @change="validateLink(content)"></a-input>
            </a-form-item>
            <a-form-item label="链接封面"
                         :wrapper-col="{span: 19}">
              <upload-image :imageUrl="content.cover"
                            @child-image="handleLinkImg($event, index)"
                            :apiName="'corpWordLink'"></upload-image>
            </a-form-item>
          </div>
          <upload-file class="word-img"
                       v-show="content.type === 'file'"
                       :apiName="'corpWord'"
                       :fileInfo='content'
                       @child-image="handleFileUpload($event, index)">
          </upload-file>
        </div>
        <p class="error-tips"
           v-if="content.error">请完善话术内容</p>
      </div>
    </a-form>

    <div class="add-content-btn">
      <a-button class="btn"
                type="primary"
                ghost
                centered
                @click="addContent">
        <PlusOutlined />添加内容
      </a-button>
      <span class="tips">添加的多个内容可一键发送</span>
    </div>

    <div class="save-btn">
      <a-button class="btn"
                type="primary"
                centered
                :loading="btnLoading"
                @click="saveContent">
        保存
      </a-button>
    </div>

  </div>
</template>

<script>
import _ from "lodash";
import RouterHeader from "@/components/RouterHeader.vue";
import UploadImage from "@/components/UploadImage.vue";
import UploadFile from "@/components/UploadFile.vue";
import { DeleteOutlined } from "@ant-design/icons-vue";
import { Radio } from "ant-design-vue";

import corpWordApi from "@/service/api/corpWord";

export default {
  name: "CorpWordCreate",
  components: {
    DeleteOutlined,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    RouterHeader,
    UploadImage,
    UploadFile,
  },
  data() {
    this.getLinkInfo = _.debounce(this.getLinkInfo, 800);
    this.changeText = _.debounce(this.changeText, 1000);
    this.validateLink = _.debounce(this.validateLink, 1000);

    const routerHeaderInfo = [
      {
        path: "/customer/corpWord",
        name: "企业话术",
      },
      {
        name: "新建话术",
      },
    ];

    const wordInfo = {
      groupId: undefined,
      title: "",
      contents: [
        {
          type: "text",
          content: "",
        },
      ],
    };

    const rules = {
      group: [
        {
          required: true,
          validator: this.validateGroup,
          trigger: "change",
        },
      ],
      title: [
        {
          max: 60,
          message: "标题长度不能大于60字",
          trigger: "blur",
        },
      ],
    };

    return {
      routerHeaderInfo,
      wordInfo,
      rules,
      validate: true,
      groupList: [],
      btnLoading: false,
    };
  },
  computed: {},
  created() {
    this.getGroupList();
  },
  methods: {
    async getGroupList() {
      const res = await corpWordApi.listGroup();
      this.groupList = res.data;
    },

    // 内容相关操作
    changeText(index) {
      this.wordInfo.contents[index].error = !this.validateText(
        this.wordInfo.contents[index]
      );
    },
    handleImgUrl(event, index) {
      console.log(event);
      this.wordInfo.contents[index].bucketUri = event.bucketUri;
      this.wordInfo.contents[index].uploadTime = event.uploadTime;
      this.wordInfo.contents[index].workWechatMediaId = event.workWechatMediaId;

      this.wordInfo.contents[index].error = false;
    },
    handleFileUpload(event, index) {
      console.log(event);
      _.assign(this.wordInfo.contents[index], event);
      this.wordInfo.contents[index].error = false;
    },
    handleLinkImg(event, index) {
      this.wordInfo.contents[index].cover = event;
      this.validateLink(this.wordInfo.contents[index]);
    },
    async getLinkInfo(content) {
      if (!/^(http|https):/.test(content.link)) {
        content.link = "https://" + content.link;
      }
      let urlInfo = await corpWordApi.getUrlBaseInfo({ url: content.link });

      if (urlInfo.title.length >= 50) {
        urlInfo.title = urlInfo.title.slice(0, 40);
      }

      content.title = urlInfo.title;
      content.summary = urlInfo.desc;
      content.cover = urlInfo.cover;
      this.validateLink(content);
    },
    clearImg(content) {
      content.bucketUri = "";
      content.uploadTime = "";
      content.workWechatMediaId = "";
    },

    // 校验相关方法
    validateContent() {
      this.validate = true;
      _.forEach(this.wordInfo.contents, (content) => {
        let flag = true;

        switch (content.type) {
          case "text":
            flag = this.validateText(content);
            break;
          case "img":
            flag = content.workWechatMediaId;
            break;
          case "link":
            flag = this.validateLink(content);
            break;
          case "file":
            flag = content.no;
            break;
        }
        content.error = !flag;
        if (!flag) {
          this.validate = false;
        }
      });
    },
    validateText(content) {
      if (_.trim(content.content) === "") {
        this.$message.error("话术内容不能为空或纯空格");
        return false;
      }
      if (content.content.length > 600) {
        this.$message.error("文本内容长度为1-600字");
        return false;
      }

      return true;
    },
    validateLink(content) {
      const flag = !!(
        content.link &&
        content.title &&
        content.summary &&
        content.cover
      );

      content.error = !flag;
      return flag;
    },
    validateGroup() {
      if (this.wordInfo.groupId) {
        return Promise.resolve();
      }

      this.$message.error("请选择分组");
      return Promise.reject("请选择分组");
    },

    // 对内容进行新增、删除及提交
    addContent() {
      if (this.wordInfo.contents.length >= 9) {
        this.$message.error("每条话术最多包含9条内容");
        return;
      }
      this.wordInfo.contents.push({
        type: "text",
      });
    },
    deleteContent(index) {
      this.wordInfo.contents.splice(index, 1);
    },
    saveContent() {
      this.$refs.formRef.validate().then(async () => {
        this.validateContent();
        if (!this.validate) {
          this.$message.error("请完善话术内容");
          return;
        }

        this.btnLoading = true;
        await corpWordApi.createDialogue(this.wordInfo);
        this.btnLoading = false;

        this.$message.success("创建成功");
        this.$router.push({ name: "corpWord_index" });

        console.log(this.wordInfo);
      });
    },
  },
};
</script>